import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/functions'
import 'firebase/analytics'

const config = {
    apiKey: process.env.apiKey,
    authDomain: process.env.authDomain,
    databaseURL: process.env.databaseURL,
    projectId: process.env.projectId,
    storageBucket: process.env.storageBucket,
    messagingSenderId: process.env.messagingSenderId,
    appId: process.env.appId,
    measurementId: process.env.measurementId,
}

export const firebaseTimestamp = firebase.firestore.Timestamp
export const firebaseFieldValue = firebase.firestore.FieldValue
export const RecaptchaVerifier = firebase.auth.RecaptchaVerifier

export const firebaseApp = firebase.initializeApp(config)

firebase.analytics()

const fbdb = firebaseApp.firestore()

export const DB = fbdb

export const Storage = firebaseApp.storage()
export const Auth = firebaseApp.auth()
export const Functions = firebaseApp.functions('europe-west3')

//Functions.useFunctionsEmulator('http://localhost:5000')

export const UidKey = 'styreportalen-uid-key'
export const OrgKey = 'styreportalen-org-key'
export const OrgNameKey = 'styreportalen-org-name-key'
export const UserNameKey = 'styreportalen-user-name-key'
export const RoleKey = 'styreportalen-role-key'
export const CalKey = 'styreportalen-calendar-key'
export const PinKey = 'styreportalen-pin-key'
export const PinFilterKey = 'styreportalen-pin-filter-key'
export const EventStatusKey = 'styreportalen-event-status-key'
export const SuperAdminKey = 'styreportalen-super-admin-key'

export const isAuthenticated = () => {
    return !!Auth.currentUser || !!localStorage.getItem(UidKey)
}

export const hasRole = (reqRoles) => {
    let roles = localStorage.getItem(RoleKey)
    if (roles) {
        roles = roles.split(',')

        if (roles.indexOf(reqRoles) >= 0) {
            return true
        } else {
            return false
        }
    } else {
        return false
    }
}

export function getOrganizationRef() {
    return DB.collection('organizations').doc(getOrgKey())
}

export const getOrgKey = () => window.localStorage.getItem(OrgKey)
export const getUidKey = () => window.localStorage.getItem(UidKey)
export const getOrgNameKey = () => window.localStorage.getItem(OrgNameKey)

export const getOrgPath = () => `organizations/${getOrgKey()}`

export const getPermissionReference = (id) =>
    DB.collection('shared')
        .doc('styreportalen')
        .collection('permissions')
        .doc(id)
