import { SET_CUSTOM_FIELDS, SET_KONTIGENT_FIELDS, SET_ORGANIZATIONS, SET_ORGANIZATIONS_LOADED, SET_REGIONS } from '../actions/index.js';

const application = (
    state = {
        organizations: [],
        organizations_fetched: false,
    },
    action
) => {
    switch (action.type) {
        case SET_ORGANIZATIONS:
            return {
                ...state,
                organizations: action.payload,
            };
        case SET_ORGANIZATIONS_LOADED:
            return {
                ...state,
                organizations_fetched: action.payload,
            };
        case SET_CUSTOM_FIELDS:
            return {
                ...state,
                custom_fields: action.payload,
            };
        case SET_REGIONS:
            return {
                ...state,
                regions: action.payload,
            };
        case SET_KONTIGENT_FIELDS:
            return {
                ...state,
                kontigent_fields: action.payload
            }
        default:
            return state;
    }
};

export default application;
