export const SET_ORGANIZATIONS = 'SET_ORGANIZATIONS';
export const SET_ORGANIZATIONS_LOADED = 'SET_ORGANIZATIONS_LOADED';
export const SET_CUSTOM_FIELDS = 'SET_CUSTOM_FIELDS';
export const SET_REGIONS = 'SET_REGIONS';
export const SET_KONTIGENT_FIELDS = 'SET_KONTIGENT_FIELDS';

export function setOrganizations(value) {
    return { type: SET_ORGANIZATIONS, payload: value };
}

export function setOrganizationsLoaded(value) {
    return { type: SET_ORGANIZATIONS_LOADED, payload: value };
}

export function setCustomFields(value) {
    return { type: SET_CUSTOM_FIELDS, payload: value };
}

export function setRegions(value) {
    return { type: SET_REGIONS, payload: value };
}

export function setKontigentFields(value) {
    return { type: SET_KONTIGENT_FIELDS, payload: value };
}