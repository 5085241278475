import React, { lazy, Suspense } from 'react';

import { Route, Switch, withRouter } from 'react-router-dom';

import { CSSTransition, TransitionGroup } from 'react-transition-group';

import PageLoader from './components/Common/PageLoader';

import Base from './components/Layout/Base';

const waitFor = (Tag) => (props) => <Tag {...props} />;

const CheckInForm = lazy(() => import('./components/Pages/CheckInForm'));


const Routes = ({ location }) => {
    const currentKey = location.pathname.split('/')[1] || '/';
    const timeout = { enter: 500, exit: 500 };

    const animationName = 'rag-fadeIn';

    return (
        <Base>
            <TransitionGroup>
                <CSSTransition key={currentKey} timeout={timeout} classNames={animationName} exit={false}>
                    <div>
                        <Suspense fallback={<PageLoader/>}>
                            <Switch location={location}>
                                <Route path="/:id" component={waitFor(CheckInForm)}/>
                                <Route path="/" component={waitFor(CheckInForm)}/>
                            </Switch>
                        </Suspense>
                    </div>
                </CSSTransition>
            </TransitionGroup>
        </Base>
    );
};

export default withRouter(Routes);
