import 'core-js/es6/string';
import 'core-js/es6/array';
import 'core-js/es6/map';
import 'core-js/es6/set';
import 'core-js/es6/object';
import 'core-js/es6/promise';
import 'core-js/es7/object';
import 'core-js/es7/array';
import 'raf/polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import App from './App';

import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import './components/Common/firebase'

import './i18n';

import moment from 'moment';
import 'moment/locale/nb';

import configureStore from './store/store';
import { PersistGate } from 'redux-persist/integration/react';

moment.locale('nb');

const { store, persistor } = configureStore();

ReactDOM.render(
    <MuiPickersUtilsProvider utils={MomentUtils} locale="nb">
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <App/>
            </PersistGate>
        </Provider>
    </MuiPickersUtilsProvider>,
    document.getElementById('app'),
);
