import { combineReducers } from 'redux';

import settingsReducer from './settings.reducer.js';
import themesReducer from './themes.reducers.js';
import applicationReducer from './application.reducer.js';

import { parse, stringify } from 'flatted';

import storage from 'localforage';
import { persistReducer, createTransform } from 'redux-persist';

export const transformCircular = createTransform(
    (inboundState, key) => stringify(inboundState),
    (outboundState, key) => parse(outboundState)
);

const persistConfig = {
    key: 'styreportalen-sentral',
    storage,
    transforms: [transformCircular],
};

const settingsConfig = {
    key: 'settings',
    storage,
    blacklist: ['offsidebarOpen', 'horizontal', 'asideToggled'],
};

export default persistReducer(
    persistConfig,
    combineReducers({
        settings: persistReducer(settingsConfig, settingsReducer),
        theme: themesReducer,
        application: applicationReducer,
    })
);
