import React from 'react';

import Footer from './Footer';

const Base = (props) => (
    <div className="wrapper">
        {props.children}

        <Footer />
    </div>
);

export default Base;
