import { applyMiddleware, createStore } from 'redux';
import reducers from './reducers/reducers';
import middlewares from './middlewares/middlewares';
import { composeWithDevTools } from 'redux-devtools-extension';
import { updateTheme } from './middlewares/themes.middleware.js';
import { persistStore } from 'redux-persist';

export default function configureStore() {
    const store = createStore(reducers, composeWithDevTools(applyMiddleware(...middlewares)));
    const persistor = persistStore(store);

    updateTheme(store.getState());

    return { store, persistor };
}
